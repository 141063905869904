<template>
  <div class="">
    <!-- <div class="col">
      <vue-simple-suggest
        class="dash-grey-card"
        v-model="chosen"
        display-attribute="displayName"
        value-attribute="id"
        :list="getSuggestionList"
        placeholder="Search anything..."
      ></vue-simple-suggest>
    </div>-->

    <div class="col mt-4">
      <h5 class="mb-2">Filter by</h5>
      <div class="row">
        <div class="col-md-3 mb-3">
          <!-- Add active class when the card is selected -->
          <div class="active blue dash-grey-card c-card u-text-center u-pt-xsmall u-pb-xsmall u-pr-small u-pl-small border-top-blue hover-scale d-flex align-items-center justify-content-between px-5">
            <h1 class="fa fa-user u-text-larger ss-blue-font-color"></h1>
            <div>
              <p class="highlights-title ss-blue-font-color">Client</p>
              <h2 class="u-mb-zero hightlight-number ss-blue-font-color">512</h2>
            </div>
          </div>
        </div>

        <div class="col-md-3 mb-3">
          <div class="active orange dash-grey-card c-card u-text-center u-pt-xsmall u-pb-xsmall u-pr-small u-pl-small border-top-orange hover-scale d-flex align-items-center justify-content-between px-5">
            <h1 class="fa fa-book u-text-larger ss-orange-font-color"></h1>
            <div>
              <p class="highlights-title">Quote</p>
              <h2 class="u-mb-zero hightlight-number ss-orange-font-color">
                231
              </h2>
            </div>
          </div>
        </div>

        <div class="col-md-3 mb-3">
          <div class="purple dash-grey-card c-card u-text-center u-pt-xsmall u-pb-xsmall u-pr-small u-pl-small border-top-purple hover-scale d-flex align-items-center justify-content-between px-5">
            <h1 class="fa fa-file-text-o  u-text-larger ss-purple-font-color"></h1>
            <div>
              <p class="highlights-title">Work Order</p>
              <h2 class="u-mb-zero hightlight-number ss-purple-font-color">
                92
              </h2>
            </div>
          </div>
        </div>

        <div class="col-md-3 mb-3">
          <div class="green dash-grey-card c-card u-text-center u-pt-xsmall u-pb-xsmall u-pr-small u-pl-small border-top-green hover-scale d-flex align-items-center justify-content-between px-5">
            <h1 class="fa fa-money u-text-larger ss-green-font-color"></h1>
            <div>
              <p class="highlights-title">Invoice</p>
              <h2 class="u-mb-zero hightlight-number ss-green-font-color">
                92
              </h2>
            </div>
          </div>
        </div>


        <div class="col-md-3 mb-3">
          <div class="red dash-grey-card c-card u-text-center u-pt-xsmall u-pb-xsmall u-pr-small u-pl-small border-top-red hover-scale d-flex align-items-center justify-content-between px-5">
            <h1 class="fa fa-folder-open u-text-larger ss-red-font-color"></h1>
            <div>
              <p class="highlights-title">Document</p>
              <h2 class="u-mb-zero hightlight-number ss-red-font-color">67</h2>
            </div>
          </div>
        </div>

        <div class="col-md-3 mb-3">
          <div class="orange dash-grey-card c-card u-text-center u-pt-xsmall u-pb-xsmall u-pr-small u-pl-small border-top-orange hover-scale d-flex align-items-center justify-content-between px-5">
            <h1 class="fa fa-credit-card-alt  u-text-larger ss-orange-font-color"></h1>
            <div>
              <p class="highlights-title">Purchase Order</p>
              <h2 class="u-mb-zero hightlight-number ss-orange-font-color">
                92
              </h2>
            </div>
          </div>
        </div>

        <div class="col-md-3 mb-3">
          <div class="green dash-grey-card c-card u-text-center u-pt-xsmall u-pb-xsmall u-pr-small u-pl-small border-top-green hover-scale d-flex align-items-center justify-content-between px-5">
            <h1 class="fa fa-money u-text-larger ss-green-font-color"></h1>
            <div>
              <p class="highlights-title">Invoice</p>
              <h2 class="u-mb-zero hightlight-number ss-green-font-color">
                92
              </h2>
            </div>
          </div>
        </div>

        <div class="col-md-3 mb-3">
          <div class="blue dash-grey-card c-card u-text-center u-pt-xsmall u-pb-xsmall u-pr-small u-pl-small border-top-blue hover-scale d-flex align-items-center justify-content-between px-5">
            <h1 class="fa fa-cubes u-text-larger ss-blue-font-color"></h1>
            <div>
              <p class="highlights-title">Inventory</p>
              <h2 class="u-mb-zero hightlight-number ss-blue-font-color">
                92
              </h2>
            </div>
          </div>
        </div>

        <div class="col-md-3 mb-3">
          <div class="green dash-grey-card c-card u-text-center u-pt-xsmall u-pb-xsmall u-pr-small u-pl-small border-top-green hover-scale d-flex align-items-center justify-content-between px-5">
            <h1 class="fa fa-money  u-text-larger ss-green-font-color"></h1>
            <div>
              <p class="highlights-title">Pricing</p>
              <h2 class="u-mb-zero hightlight-number ss-green-font-color">
                92
              </h2>
            </div>
          </div>
        </div>

        <div class="col-md-3 mb-3">
          <div class="red dash-grey-card c-card u-text-center u-pt-xsmall u-pb-xsmall u-pr-small u-pl-small border-top-red hover-scale d-flex align-items-center justify-content-between px-5">
            <h1 class="fa fa-truck  u-text-larger ss-red-font-color"></h1>
            <div>
              <p class="highlights-title">Supplier</p>
              <h2 class="u-mb-zero hightlight-number ss-red-font-color">
                92
              </h2>
            </div>
          </div>
        </div>

        <div class="col-md-3 mb-3">
          <div class="orange dash-grey-card c-card u-text-center u-pt-xsmall u-pb-xsmall u-pr-small u-pl-small border-top-orange hover-scale d-flex align-items-center justify-content-between px-5">
            <h1 class="fa fa-users  u-text-larger ss-orange-font-color"></h1>
            <div>
              <p class="highlights-title">Employee</p>
              <h2 class="u-mb-zero hightlight-number ss-orange-font-color">
                92
              </h2>
            </div>
          </div>
        </div>

        <div class="col-md-12 mt-4" v-if="results.length === 0">
          <h4>No results found!</h4>
        </div>
        <div class="col-md-12 mt-4" v-if="results.length > 0">
          <h5 class="mb-3 filter-by-label ">Results</h5>
          <div class="c-card u-pt-xsmall u-pb-xsmall u-pr-small u-pl-small border-left-purple mb-3 hover-shadow clickable" v-for="proj in results" :key="proj.intProjectID">
            <div class="d-flex">
              <div class="col-12" @click.prevent="showDetails(proj)">
                <h2>{{ proj.businessName }}</h2>
                <div class="d-flex mb-1">
                  <div v-if="proj.htmlViewStatus" v-html="proj.htmlViewStatus" class="mr-2"></div>
                  <div v-if="proj.htmlViewTechnicianStatus" v-html="proj.htmlViewTechnicianStatus" class="mr-2"></div>
                </div>
                <p class="mb-1">{{ proj.location }}</p>
                <a class="link mt-2 mb-1 d-inline-block" @click.prevent="showDetails(proj)">View Details</a>
              </div>
            </div>
          </div>
        </div>
        <!-- <router-view name="sidepanel"></router-view> -->
      </div>
    </div>
  </div>
</template>

<script>
  // import UXInventoryService from "@/services/ux/uxinventory";
  // import "vue-simple-suggest/dist/styles.css"; // Optional CSS
  import axios from "axios";
  import API_CONFIG from "@/config/API_CONFIG";
  import UtilityString from "@/utilities/strings";
  import AuthService from "@/auth/authService";
  import ApplicationService from "@/services/application";
  import ProjectService from "@/services/projects";
  const projectApi = new ProjectService();

  const app = new ApplicationService();
  export default {
    data() {
      return {
        chosen: "",
        search: null,
        loading: false,
        results: [],
      };
    },
    watch: {
      $route: {
        deep: true,
        handler(value) {
          this.search = value.query && value.query.q ? value.query.q : "";
          if (value.query && value.query.q) {
            this.loadData();
          }
        },
      },
    },
    mounted() {
      this.search = this.$route.query.q;
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        if (vm.$route.name === "SearchPage") {
          vm.loadData();
        }
      });
    },
    methods: {
      // getSuggestionList() {
      //   let inventoryService = new UXInventoryService();
      //   let results = inventoryService.getInventoryList(this.chosen);
      //   return results;
      // }
      showDetails(data) {
        // this.$router.push({
        //   name: "search-details",
        //   params: {
        //     id: item.intProjectID,
        //   },
        // });
        //Set Id of the currently selected/edit table item
        let currentPath = this.$route.path + "/" + data.intProjectID;

        let route = {name: "search-details", params: {id: data.intProjectID}};

        //Update the router
        if (currentPath !== this.$route.path) {
          this.$router.push({name: route.name, params: {id: data.intProjectID}});
        }

        app.showSidePanel(null, data, route);
      },

      // async showDetails(data) {
      //   let newPath = "/search/details/" + data.intProjectID;
      //   let route = {name: "search-details", params: {id: data.intProjectID}};

      //   //Update the router
      //   if (newPath !== this.$route.path) {
      //     this.$router.push(route);
      //   }

      //   const projectDetails = await projectApi.getProjectDetails(data.intProjectID);

      //   this.setPanelProperties(projectDetails, route);
      // },
      // setPanelProperties(data, route) {
      //   let sidePanel = this.$store.getters.getSidePanel;

      //   sidePanel.isOpen = true;

      //   sidePanel.data = data;

      //   sidePanel.panelObj.show();

      //   sidePanel.panelObj.promise.then((result) => {});

      //   //Send Data to Side Panel
      //   this.$store.dispatch("setSidePanel", sidePanel);
      // },

      async loadData() {
        try {
          this.loading = true;
          const utility = new UtilityString();
          const config = new API_CONFIG();
          const apiKey = config.API_KEY;

          axios.defaults.baseURL = config.API_URL;
          const apiHeaderObj = {
            headers: {
              "x-functions-key": apiKey,
            },
          };
          const param = this.readyFilters();
          const paramString = utility.serializeObject(param);
          const result = await axios.get("search/dashboard?" + paramString, apiHeaderObj);
          this.results = result.data.resources;
          this.loading = false;
        } catch (e) {}
      },
      readyFilters() {
        let filter = {};
        filter.id = 0;
        filter.maximumRows = 30;
        filter.projectRefNumber = this.search;
        const auth = AuthService.getInstance();
        return Object.assign(filter, auth.getAuthObject());
      },
    },
  };
</script>
<style lang="scss">

.active.orange {
    background: #f59209c4 !important;
    &.border-top-orange, * {
      // border-color: #fff !important;
      color: #fff !important;
      opacity: 1 !important;
    }
}

.active.blue {
    background: #00abfec4 !important;
    &.border-top-blue, * {
      color: #fff !important;
      opacity: 1 !important;
    }
}

.active.purple {
    background: #6e0677bf  !important;
    &.border-top-purple, * {
      color: #fff !important;
      opacity: 1 !important;
    }
}

.active.red {
    background: #f1302bc4  !important;
    &.border-top-red, * {
      color: #fff !important;
      opacity: 1 !important;
    }
}

.active.green {
    background: #27ae5fc4 !important;
    &.border-top-green, * {
      color: #fff !important;
      opacity: 1 !important;
    }
}
</style>
